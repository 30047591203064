let appName = process.env.VUE_APP_APPLICATION_NAME;
let importFrameworks = {};

config.style.frameworks.forEach((key) => {
    importFrameworks[key] = false;
});

let context;


// require frameworks from the app overrides folder
//context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/assets\/frameworks.*\.js$/);
context = require.context('@/', true, /overrides\/client\/applications\/.*\/assets\/frameworks.*\.js/);

context.keys().forEach(key => {

    // filter only the modules for out application
    if (! key.startsWith('./overrides/client/applications/'+appName)) {
        return;
    }
    // get the filename thats being loaded
    let cleanKey = key.split('/').pop().replace('./', '').replace('.js', '');

    if (importFrameworks.hasOwnProperty(cleanKey) && importFrameworks[cleanKey] === false) {
        module = context(key);
        importFrameworks[cleanKey] = true;
    }
});


// require frameworks from the app folder
context = require.context('@/client/applications/', true, /^\.\/.*\/assets\/frameworks.*\.js$/);
context.keys().forEach(key => {

    // filter only the modules for out application
    if (! key.startsWith('./'+appName)) {
        return;
    }
    // get the filename thats being loaded
    let cleanKey = key.split('/').pop().replace('./', '').replace('.js', '');

    if (importFrameworks.hasOwnProperty(cleanKey) && importFrameworks[cleanKey] === false) {
        module = context(key);
        importFrameworks[cleanKey] = true;
    }
});



// require frameworks from the overrides folder
//context = require.context('@/overrides/client/assets/frameworks', true, /\.js/);
context = require.context('@/', true, /\/overrides\/client\/assets\/frameworks\/.*\.js/);

context.keys().forEach(key => {
    let cleanKey = key.split('/').pop().replace('.js', '');
    if (importFrameworks.hasOwnProperty(cleanKey) && importFrameworks[cleanKey] === false) {
        module = context(key);
        importFrameworks[cleanKey] = true;
    }
});


// require remainings frameworks from the client folder
context = require.context('@/client/assets/frameworks', true, /\.js/);
context.keys().forEach(key => {
    let cleanKey = key.replace('./', '').replace('.js', '');

    // if this file is required by us, and was not yet imprted - get it now
    if (importFrameworks.hasOwnProperty(cleanKey) && importFrameworks[cleanKey] === false) {
        module = context(key);
        importFrameworks[cleanKey] = true;
    }
});





